import React from "react"
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'

import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import '../css/sobre-nos.css'



class About extends React.Component {

    render() {
        return (
            <>
                <Metatags
                    title="Maxipack"
                    description="Ampla área produtiva e de armazenagem, 2 docas cobertas para recepção e expedição de caminhões e carretas, Equipamentos atualizados, Área produtiva isolada e com aprovação de orgãos competentes como Anvisa, prefeitura e bombeiros."
                    url="https://www.maxipack.com.br/sobre-nos/"
                    image="https://www.maxipack.com.br/maxipack.png"
                    keywords="Ampla área produtiva, armazenagem, docas, equipamentos atualizados, arear produtiva isolada, anvisa"
                />
                <Navbar />

                <Container>
                    <Row>
                        <Col>
                            <img
                                className="d-block w-100"
                                src={require("../images/Testeira_Quem_Somos_4.jpg")}
                                alt="Quem somos"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} />
                        <Col md={8}>
                            <div className="homeText">
                                <p>Ampla área produtiva e de armazenagem com mais de 2.800m<sup>2</sup> com flexibilidade de ampliação rápida e segura.</p>
                                <p>2 docas cobertas para recepção e expedição de caminhões e carretas.</p>
                                <p>Equipamentos atualizados e em quantidade suficiente para atender grandes produções.</p>
                                <p>Área produtiva isolada e com aprovação de orgãos competentes como Anvisa, prefeitura e bombeiros.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mvv">
                        <Col md={2} />
                        <Col md={8} >
                            <Row>
                                <Col md={5} >
                                    <div className="cardGreen">
                                        <h4>MISSÃO</h4>
                                        <div className="white-dash">&nbsp;</div>
                                        <p>Atender e superar as expectativas dos nossos clientes desenvolvendo o melhor método, viabilidade e eficácia no ramo de embalagens.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={5} >
                                    <div className="cardGreen">
                                        <h4>VISÃO</h4>
                                        <div className="white-dash">&nbsp;</div>
                                        <p>Viabilizar soluções para minimizar os custos para nossos clientes de forma sustentável e atendendo os padrões de qualidade.<br />&nbsp;</p>
                                    </div>
                                </Col>
                                <Col md={5} >
                                    <div className="cardGreen">
                                        <h4>VALORES</h4>
                                        <div className="white-dash">&nbsp;</div>
                                        <p>Qualidade<br />Pontualidade<br />Transparência<br />Responsabilidade Social<br />Comprometimento com o cliente.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div style={{ height: "60px" }} />
                    <Row>
                        <Col>
                            <img
                                className="d-block w-100"
                                src={require("../images/Testeira_Quem_Somos_2.jpg")}
                                alt="Quem somos"
                            />
                        </Col>
                    </Row>

                    <div style={{ height: "40px" }} />
                    <Row style={{ backgroundColor: "#f5f5f5", margin: 0 }}>
                        <Col md={2} />
                        <Col md={8}>
                            <div className="homeText">
                                <h4>GESTÃO DE PESSOAS</h4>
                                <p>Nosso diferencial é a valorização dos nossos colaboradores, uma vez que nossos resultados são atingidos com a participação de todos, criando um local de trabalho baseado na confiança no respeito mútuo e na inclusão social. </p>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ height: "40px" }} />
                </Container>


                <Footer />
            </>
        )
    }
}

export default About